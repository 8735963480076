<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="선택해주세요" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>'휘파람' 생방송 계약</h2>
		<p>'휘파람 라이브 방송 계약'은 Pix Technology PTE. LTD.(이하 '당사')와 귀하(귀하가 자연인, 법인 또는 기타 조직임) 간에 체결되는 권리를 규제하는 계약입니다.
			법적 구속력이 있는 전자 계약(이하 "본 계약"). "동의함"을 선택하거나 "본 계약을 읽었으며 이를 준수합니다" 버튼을 클릭한다는 것은 귀하가 본 계약의 모든 조항을 주의 깊게 읽고, 완전히 이해하고,
			조건 없이 완전히 수락했음을 의미합니다. </p>

		<h3>Uni Live 사용자 라이브 방송 기본 규칙</h3>
		<p>
			라이브 방송 콘텐츠
		</p>
		<p>
			1. 불법 및 유해 정보
			정치적으로 민감한 주제, 민족 분열, 종교적 극단주의와 관련된 콘텐츠의 유포를 금지합니다.
			폭력, 유혈, 공포, 도박, 마약 또는 기타 불법 행위를 포함한 내용을 방송하는 것은 엄격히 금지됩니다.
			미신(예: 점, 운세)에 대한 과도한 홍보를 피하고 신중하게 다루어야 합니다.
		</p>
		<p>
			2. 음란 및 저속한 콘텐츠
			노출, 성적 암시, 저속한 공연 또는 모든 형태의 음란 콘텐츠는 엄격히 금지됩니다.
			의상은 과도하게 노출되지 않아야 합니다(예: 가슴, 엉덩이 노출, 시스루 의상 등). 이를 위반할 경우 방송이 중단될 수 있습니다.
			성적인 주제와 관련된 도발적인 언행은 허용되지 않습니다.
		</p>
		<p>
			3. 타인의 권리 침해
			타인의 개인 정보(예: 이름, 주소)를 허가 없이 공개하는 것은 금지됩니다.
			사이버 괴롭힘, 악의적인 공격, 또는 명예훼손 행위는 엄격히 금지됩니다.
		</p>
		<p>
			4. 허위 및 사기 행위
			유언비어, 허위 광고, 또는 확인되지 않은 정보(예: 건강 제품의 효능을 과장하는 행위)를 유포하는 것은 금지됩니다.
			사기, 피라미드 판매, 또는 사용자를 개인 거래로 유도하는 행위는 금지됩니다.
		</p>
		<p>
			5. 미성년자 관련 콘텐츠
			미성년자를 철저히 보호해야 합니다. 어린이는 라이브 방송에 참여할 수 없습니다.
			미성년자의 신체적, 정신적 건강에 해를 끼칠 수 있는 콘텐츠를 표시하는 것은 금지됩니다.
		</p>

		<h3>Uni Live 동영상 업로드 기본 규칙</h3>
		<p>
			콘텐츠 가이드라인(커뮤니티 규범)
		</p>
		<p>
			금지된 폭력적 콘텐츠: 폭력, 상해, 학대 또는 테러 관련 콘텐츠(실제 또는 모방 포함)를 업로드하는 것은 금지됩니다.
		</p>
		<p>
			금지된 음란 및 노골적인 콘텐츠: 노출, 성적 암시, 음란 또는 모든 형태의 성인 콘텐츠는 금지됩니다.
		</p>

		<p>
			금지된 혐오 발언: 인종, 종교, 성별, 성적 지향, 국적 등을 기반으로 특정 집단을 차별하거나 공격하거나 증오를 조장하는 콘텐츠의 게시를 금지합니다.
		</p>
		<p>
			금지된 괴롭힘 및 희롱: 타인을 모욕, 협박, 또는 괴롭히는 콘텐츠(미성년자를 대상으로 한 부적절한 행동 포함)를 업로드하는 것은 금지됩니다.
		</p>
		<p>
			금지된 위험 행위: 부상으로 이어질 수 있는 위험한 챌린지, 장난, 또는 불법 행위(예: 마약 사용, 무기 취급)를 보여주는 동영상의 업로드는 금지됩니다.
		</p>


		<h3>제1조 일반 조항</h3>
		<p>1. UniLive 플랫폼은 Pix Technology PTE. LTD.와 Pix Technology PTE. LTD.를 포함하되 이에 국한되지 않는 계열사가 소유한
			생방송 플랫폼을 의미합니다. 등록 요구 사항 및 규칙에 따라 귀하는 합법적으로 운영되는 UniLive 플랫폼(이하 "플랫폼")에서 라이브 방송 서비스 제공자(또는 "라이브 방송 당사자")가 되기를 신청하여
			플랫폼 사용자에게 다음과 같은 서비스를 제공합니다. 온라인 라이브 비디오 콘텐츠의 방송 서비스인 경우, 귀하가 당사 플랫폼에서 서비스를 제공하는 기간은 계약 기간 내에 있는 것으로 간주됩니다. 당사는
			귀하가 참여, 편집, 제작한 앞서 언급한 업로드 영상 콘텐츠를 사전에 검토하지 않으며, 해당 영상을 편집, 정리, 수정, 처리하는 데 주도권을 갖지 않습니다. </p>
		<p>2. 본 계약에 서명하기 전에 귀하는 당사의 규칙과 요구 사항을 완전히 이해했으며 본 계약에 규정된 라이브 방송 당사자의 책임과 의무를 수행할 자격과 능력을 갖추고 있습니다. 본 계약은 귀하에 대한
			유효하고 구속력이 있으며 집행 가능한 법적 의무를 구성합니다. 귀하는 본 계약에 따른 모든 용어와 정의를 명확하게 알고 있으며 이에 대해 의심의 여지가 없습니다. </p>
		<p>3. 귀하는 당사에 서비스를 제공할 때 현지 법률의 관련 조항을 준수할 것을 약속하고 선언하며, 본 계약을 이행한다는 명목으로 중국 및 현지 법률을 위반하는 기타 행위에 가담해서는 안 됩니다. .
		</p>
		<p>4. 귀하와 당사는 노동법적 차원에서 고용, 노동 또는 서비스 관계를 구성하지 않으며 당사는 귀하에게 사회 보험료 및 혜택을 지급할 필요가 없습니다. </p>
		<p>5. 귀하는 당사의 사전 서면 동의 없이 제3자 경쟁 플랫폼에서 생방송 관련 활동에 참여할 수 없습니다. </p>
		<p>6. UniLive 앵커로 등록하거나 UniLive 플랫폼에서 라이브 방송 서비스를 수행하기 전에 귀하는 제3자 플랫폼과 서명하지 않았거나 기존 독점 라이브 방송 계약을 체결하지 않았음을 확인했습니다.
		</p>
		<p>7. 두 당사자 간의 협력 기간 동안 귀하의 콘텐츠 라이브 방송, 업로드, 게시 또는 전송 권리는 "UniLive 사용자 서비스 계약"의 조건을 따릅니다. </p>

		<h3>제2조 당사의 권리와 의무</h3>
		<p>1. 당사는 라이브 방송 당사자를 위한 플랫폼 운영 시스템 및 관리 규칙을 제정하고 이를 본 계약의 일부로 만들 권리가 있습니다. 당사는 귀하를 관리하고 감독할 권리가 있으며, 운영 조건에 따라 해당
			규칙을 조정하거나 변경하는 경우 귀하는 이에 대해 이해하고 동의합니다. </p>
		<p>2. 당사는 귀하에 대한 보상이나 벌칙을 설정(취소)하기 위해 귀하를 검사하고 평가할 권리가 있으며, 구체적인 검사 항목 및 기준은 귀하의 추가 동의 없이 당사가 별도로 정합니다. 당사가 귀하와 별도로
			정식 생방송 협력 계약 체결을 요청하는 경우, 귀하는 다른 방식으로 서명을 거부하거나 거부해서는 안 됩니다. [그렇지 않은 경우 귀하는 일회성 손해배상금 [50,000](자본금: 5,000)위안을 당사에
			지불해야 합니다. ] . </p>
		<p>3. 당사는 라이브 방송 서비스 개선을 위한 제안과 의견을 제출할 권리가 있습니다. 귀하는 당사의 제안과 의견을 받은 후 근무일 기준 [3]일 이내에 해당 사항을 수정해야 합니다. 그렇지 않으면 귀하는
			제한할 권리가 있습니다. , UniLive 라이브 방송실 사용을 폐쇄, 재활용 또는 종료합니다. 귀하에게 지불해야 하는 서비스 요금에서 해당 공제(있는 경우)로 인해 특정 손실이 발생할 수 있으며, 해당
			손실은 귀하가 부담합니다. , 당사는 어떠한 책임도 지지 않습니다. . </p>
		<p>4. 당사는 귀하의 실시간 방송과 관련된 문제에 대해 최종 결정을 내릴 최종 권리를 갖습니다. </p>
		<p>5. 당사는 귀하의 이름(실명, 필명, 화면 이름, 이전 이름 ​​및 귀하의 신원을 나타내는 모든 텍스트 기호를 포함하되 이에 국한되지 않음), 초상(실제 인물의 초상을 포함하되 이에 국한되지 않음)을
			사용할 권리가 있습니다. 사람, 만화 초상화 등)을 통해 당사 플랫폼에서 다양한 프로모션을 진행합니다. </p>
		<p>6. 당사는 플랫폼 기술 서비스 지원을 제공할 책임이 있으며 플랫폼 서비스 요금 정산(있는 경우)도 담당합니다. </p>

		<h3>제3조 귀하의 권리와 의무</h3>
		<p>1. 실제 신원정보와 개인정보를 이용해야 하며, 등록 및 인증을 위해 주민등록정보, 사업자등록정보, 조직코드 정보를 허위 또는 허위로 이용해서는 안 됩니다. 귀하의 개인 정보에 변경 사항이 있는 경우
			즉시 업데이트해야 합니다. 당사는 미성년자의 라이브 방송을 금지하며, 법정대리인이 미성년자에게 본 약관에서 규정한 웹캐스팅 및 라이브 스트리밍 서비스를 제공하기를 원하는 경우에는 해당 서비스의 내용이
			미성년자에게 적합한지 여부를 법정대리인으로서 판단하여야 하며, 법정대리인은 모든 책임을 부담하여야 합니다. 이로 인한 결과. 귀하는 본 계약을 이행함으로써 제3자의 정당한 이익을 침해하지 않을 것을
			약속합니다. 본 계약에 대한 귀하의 수락 및 이행은 귀하를 구속하는 법적 문서를 위반하지 않으며 당사가 제3자에 대해 책임을 지지 않습니다. </p>
		<p>2. 라이브 방송에 필요한 네트워크, 영상, 음성 지원 장비에 대한 책임이 있으며, 라이브 방송 이미지가 선명하고 음성 품질이 깨끗하고 안정적인지 확인합니다. </p>
		<p>3. 귀하는 플랫폼이 정한 생방송실 신청 절차를 따르고 플랫폼에서 요구하는 신청 자료를 제출하며 해당 보증금을 자발적으로 지불할 의사가 있음을 약속합니다. </p>
		<p>4. 귀하는 라이브 방송실을 라이브 방송에만 사용해야 하며, 라이브 방송 이외의 다른 활동에 사용하지 않을 것을 약속합니다. <b>상품 라이브 스트리밍을 수행할 때 귀하와 귀하의 공급업체는 중국 법률을
				준수하고 당사가 공표한 "판매자 서비스 계약"의 관련 조항을 이행해야 합니다. </b></p>
		<p>5. 귀하가 본 계약에 따라 수행하거나 본 플랫폼에 게시하는 모든 정보/정보/연설/콘텐츠에는 중화인민공화국의 관련 법률, 규정 및 조항을 위반하는 콘텐츠가 포함되어서는 안 됩니다. 국가 안보를 위협하는
			내용, 외설, 허위, 불법, 명예훼손(상업적 명예훼손 포함), 불법적으로 위협하거나 불법적으로 괴롭히는 내용, 타인의 지적재산권, 개인권, 영업비밀 또는 기타 합법적인 권리와 이익을 침해하는 내용으로
			제한됩니다. 공공질서 및 미풍양속을 위반하거나 이러한 내용을 언급하는 내용의 내용에 대한 링크입니다. </p>
		<p>6. 귀하는 당사와 당사 플랫폼의 이미지를 적극적으로 유지할 것을 약속하며 당사 및/또는 당사 플랫폼의 이미지나 이익을 손상시키는 조치를 취하지 않을 것입니다. 본 계약 기간 동안 및 종료 후에도 계약에
			따라 귀하는 어떤 채널(웹사이트, 블로그, Weibo, WeChat, QQ 채팅 그룹, 플레이어 모임 등을 포함하되 이에 국한되지 않음)을 통해 당사 및/또는 당사 플랫폼에 불리한 발언을 암시하거나
			게시해서는 안 됩니다. </p>
		<p>7. 당사의 서면 동의 없이 귀하는 어떤 경우에도 어떤 형태(텍스트, 구두 방송, 비디오 패치 등을 포함하되 이에 국한되지 않음)로 제3자 경쟁 플랫폼의 관련 정보를 언급할 수 없으며, 당사 플랫폼의
			기존 사용자, 기타 생방송 당사자 및 당사 직원이 연락 지원, 설득 등을 포함하되 이에 국한되지 않는 정보 또는 편의를 제공하기 위해 다른 제3자 경쟁 플랫폼에 입장하도록 안내하거나 제공합니다. </p>

		<h3>제4조 서비스 이용요금 및 정산</h3>
		<p>1. 귀하가 플랫폼 사용자에게 라이브 방송 서비스를 제공한다는 전제 하에, 사용자는 귀하에게 소비를 위한 가상 선물을 제공할 수 있으며 귀하는 당사의 정산 요건 및 규칙에 따라 정산 관련 소득(있는
			경우)을 신청할 수 있습니다. 기술 서비스 비용의 일정 비율을 청구하고 해당 세금을 공제한 후 지불해 드립니다. 우리는 비정상적인 수단을 통해 얻은 가상 선물의 소비를 독립적으로 판단하고 처리할 권리가
			있습니다. </p>
		<p>2. 귀하가 받는 서비스 수수료는 관련 국가 법률 및 규정에 따라 과세되어야 합니다. 귀하가 받는 서비스 수수료는 사용자 센터에 입력한 은행 계좌로 지급됩니다. 로그인할 수 있습니다. 관련 정보는 에서
			조회할 수 있습니다. 당사 플랫폼의 수익 기록(정산 데이터에는 세금 데이터가 포함됨) </p>
		<p>3. 귀하가 자연인이고 당사 플랫폼에 등록하고 개인 인증을 통과한 경우 당사는 귀하가 받은 서비스 수수료를 귀하가 사용자 센터에 입력한 개인 은행 계좌로 지불할 권리가 있습니다. 개인 또는 기타 조직이
			당사 플랫폼에 등록하고 대행사 인증을 통과한 경우 당사는 귀하가 대행사 인증 페이지에 입력한 대행사 계정으로 귀하가 받는 서비스 수수료를 지불할 권리가 있지만 귀하는 유효한 값을 당사에 제공해야 합니다.
			당사가 결제하기 전 영업일 기준 5일 이내에 동일한 금액(송장 이름은 생방송 서비스 요금)에 대한 특별세금계산서를 추가했습니다. 우리 쪽에서 계약하세요. 당사가 귀하가 기재한 계좌에 따라 서비스 요금을
			지급하는 경우, 본 약관에 규정된 지급 의무를 이행한 것으로 간주됩니다. 귀하가 법인 또는 기타 단체인 경우 귀하의 직원 또는 소속 앵커가 계정 및 생방송실의 관리 및 운영에 소요되는 비용은 귀하와 귀하의
			직원 또는 소속 앵커 사이에서 정산됩니다. 귀하가 수수료 정산으로 인해 분쟁, 소송 또는 보상으로 당사에 손실을 초래하는 경우(귀하가 직원 또는 앵커의 급여를 체납하는 경우를 포함하되 이에 국한되지
			않음), 당사는 다음과 같은 권리를 갖습니다. 사전에 지불해야 하는 서비스 수수료 금액을 공제하고 당사는 귀하로부터 부족분을 회수할 권리가 있습니다. </p>
		<p>4. 귀하는 귀하가 입력한 계정 정보가 정확하고 사실이며 유효함을 보증합니다. 계정 정보로 인해 당사 측의 결제 오류가 발생한 경우 이에 대한 책임은 전적으로 귀하에게 있습니다. 동시에, 계정 정보를
			변경해야 하는 경우에는 적시에 서면으로 알려주셔야 하며, 새로운 계정 정보는 신청서 제출 후 다음 정산월부터 적용되며 당사에서 검토 및 승인됩니다. </p>

		<h3>제5조 비밀유지제도</h3>
		<p>1. 귀하는 당사의 기밀 유지 시스템을 엄격히 준수해야 하며 당사의 영업 비밀을 무기한으로 유지할 것을 약속해야 합니다. 귀하가 계약을 위반하여 당사의 영업비밀 및 정보를 사용 또는 공개하여 당사의
			명성, 명성 또는 경제에 직간접적인 손실을 입힌 경우, 귀하는 RMB [100,000] 위안의 지체상 손해배상금을 당사에 배상해야 합니다. 우리가 손실을 입으면 당신도 우리의 손실을 배상해야 합니다.
		</p>
		<p>2. 영업 비밀은 당사가 제공했거나 양 당사자 간의 협력 중에 귀하가 알게 된 비밀 또는 당사가 제3자에 대해 기밀 유지 의무를 지는 것으로 당사의 비즈니스와 관련되어 당사에게 경제적 이익을 가져올 수
			있는 비밀을 의미합니다. 기술 정보, 사업 정보, 당사의 행정 관리와 관련된 정보 및 문서(본 계약 및 관련 계약의 내용 포함), 귀하의 금액 및 결제 방법을 포함하되 이에 국한되지 않는 실용적이고
			공개적으로 알려지지 않은 정보, 당사가 획득한 표준, 권리 소유 방법, 승인 방법, 고객 목록, 다른 생방송자 목록, 연락처 정보, 서비스 요금, 당사 직원 목록 등은 대중에게 공개되지 않습니다. </p>
		<p>3. 귀하는 본 계약을 엄격히 준수해야 하며 당사의 서면 승인이나 동의 없이 당사의 영업 비밀을 사용해서는 안 됩니다.</p>
		<ul>
			<li>(1) 어떤 방식으로든 제3자 또는 불특정 대중에게 유포하거나 유출하는 행위</li>
			<li>(2) 본 계약의 목적 이외의 목적으로 영업 비밀을 사용합니다. </li>
		</ul>
		<p>4. 본 계약이 종료된 후 귀하는 당사의 모든 영업 비밀을 당사에 반환하거나 당사의 감독 하에 당사의 영업 비밀을 기록한 모든 문서를 파기해야 합니다. </p>
		<p>5. 본 조항의 조항은 본 계약이 종료된 후에도 유효합니다. </p>

		<h3>제6조 계약의 변경, 철회 및 해지</h3>
		<p>1. 당사는 필요한 경우 본 계약을 변경, 정지 또는 종료할 수 있으며 해당 페이지에 이를 공지할 수 있습니다. 변경된 계약이 해당 페이지에 게시되면 원래 계약을 효과적으로 대체합니다. 본 계약 조건이
			변경된 후에도 귀하가 당사 플랫폼 사용자에게 라이브 방송 및 기타 라이브 방송 서비스를 계속 제공하는 경우 귀하는 변경된 계약을 인지하고 이에 동의한 것으로 간주됩니다. 귀하가 본 계약의 변경 사항에
			동의하지 않는 경우 즉시 서면으로 당사에 통보하고 당사 플랫폼의 모든 라이브 방송 서비스를 중단해야 합니다. </p>
		<p>2. 양 당사자는 본 계약 종료에 대한 합의에 도달함으로써 계약을 종료할 수 있습니다. </p>
		<p>3. 귀하가 다음과 같은 상황에 해당하는 경우, 당사는 사전 통지 없이 즉시 본 계약을 해지할 수 있습니다.</p>
		<ul>
			<li>(1) 귀하가 본 계약의 진술과 약속을 위반한 것으로 확인되었습니다.</li>
			<li>(2) 귀하의 행동이 직간접적으로 당사의 이익에 심각한 손해를 끼치는 경우</li>
			<li>(3) 국내법 및 규정 위반</li>
			<li>(4) 본 계약에 규정된 기타 의무 위반</li>
			<li>(5) 수동성 또는 무활동 등 당사의 요구 사항을 충족하지 않는 방식(계약 위반이 아닌 경우에도)으로 본 계약을 이행하고 당사가 통지한 후 10일 이내에 시정하지 않는 방식으로 본 계약을 이행하는
				행위,</li>
			<li>(6) 비정상적인 상황 발생으로 인해 귀하가 본 계약에 따른 서비스 제공에 적합하지 않다고 판단하고, 통지 후 10일 이내에 비정상적인 상황이 제거되지 않은 경우,</li>
			<li>(7) 사업 조정으로 인해 더 이상 실시간 방송 서비스를 제공하지 않습니다. </li>
		</ul>
		<p>4. 본 약관 제6조 제1항 및 제2항에 의한 계약의 해지 또는 해지로 인해 당사는 본 약관 제4조의 규정 및 실제 조건에 따라 귀하와 서비스 이용요금을 정산합니다. 우리 플랫폼의 시간 정책. </p>
		<p>5. 본 계약 제6조 3항에 따른 계약 해지 또는 해지로 인해 당사는 귀하의 계정에서 미결제 서비스 요금을 모두 공제할 권리가 있으며, 귀하에게 다음에 대한 책임을 지도록 요구할 권리가 있습니다. 합의된
			계약 위반. </p>

		<h3>제7조 계약 위반에 대한 책임</h3>
		<p>1. 다른 당사자가 당사를 상대로 소송이나 소송을 제기하거나 당사의 평판을 손상시키는 결과를 초래하는 허위 진술이나 명시한 약속 위반으로 인해 당사에 발생한 모든 직접적인 결과와 간접 비용, 손실 및
			손실은 귀하가 부담해야 합니다. 소송에 대해 당사가 지불한 관련 비용 및 변호사 비용을 포함한 보상. </p>
		<p>2. 본 계약에서 달리 합의하지 않는 한, 귀하가 본 계약의 조항을 위반하거나 귀하가 제공하는 서비스가 당사의 요구 사항을 충족하지 않는 경우 당사는 다음을 포함하되 이에 국한되지 않는 상응하는 제한
			또는 처벌 조치를 일방적으로 취할 권리가 있습니다. 휘슬 라이브 방송실 이용 제한, 폐쇄, 재활용, 종료, 개별 서비스(동영상 라이브 방송 등) 제한 또는 중단, 실제 상황에 따라 이용 재개 여부 결정,
			미정산 서비스 이용료를 계정에서 차감 . </p>


	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/LiveAgreementZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/LiveAgreementEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/LiveAgreementKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/LiveAgreementJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/LiveAgreementRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/LiveAgreementVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/LiveAgreementTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/LiveAgreementZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/LiveAgreementEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/LiveAgreementKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/LiveAgreementJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/LiveAgreementRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/LiveAgreementVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/LiveAgreementTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}

h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}

li {
	font-size: 0.3rem;
}
</style>